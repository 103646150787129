import { Link } from "gatsby"
import React from "react"
import Logo from "../logo/logo.svg"
import Seo from "../components/Seo"

export default function NotFound() {
  return (
    <>
      <Seo
        index={false}
        title="Page non trouvée | Chanoyu"
        description="Cette page s'affiche car vous tentez d'accéder à une page qui n'existe pas. Nous sommes désolé pour la gêne occasionnée."
      />

      <div className="flex flex-col min-h-screen pt-16 pb-12 bg-white">
        <main className="flex flex-col justify-center flex-grow w-full px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex justify-center flex-shrink-0">
            <Link to="/" className="inline-flex">
              <span className="sr-only">Chanoyu</span>
              <img
                className="w-auto h-12"
                src={Logo}
                alt="Logo du Chanoyu"
                title="Logo du Chanoyu"
              />
            </Link>
          </div>
          <div className="py-16">
            <div className="text-center">
              <p className="text-sm font-semibold tracking-wide text-green-600 uppercase">
                Erreur 404
              </p>
              <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                Page non trouvée.
              </h1>
              <p className="mt-2 text-base text-gray-500">
                Comment êtes-vous arrivé là ? <br />
                Nous sommes désolés mais cette page n'existe pas 🙇‍♂️.
              </p>
              <div className="mt-6">
                <Link
                  to="/"
                  className="text-base font-medium text-green-600 hover:text-green-500"
                >
                  Retour à la page principale
                  <span aria-hidden="true"> &rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
